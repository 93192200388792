import axios from "axios";


const BASE_URL_CF = process.env.VUE_APP_CF_D2C
const BASE_URL = process.env.VUE_APP_BASE_URL_API;

export const zoneCosts = async (zipCode) => {
    try {
       const {data} = await axios.get(BASE_URL_CF + '/AIG_D2C_AON_MARSH_GET_ZONE?zipCode=' + zipCode)
       return data
    } catch (error) {
        throw error
    }
}

export const emiliPayments = async (body) => {
    try {
       const { data } = await axios.post(BASE_URL_CF + '/AIG_HOGAR_PAYMENT_EMILI_PROCESS_PAYMENT_V3', body)
       return data
    } catch (error) {
        console.log("error: " + error);
        throw error
    }
}

export const getPlans = async (planId, secureAmount, zona_hidro, zona_sismica, dataCustomer, companyId) => {
    try {

        const body = {
            planId, secureAmount, zona_hidro, zona_sismica, dataCustomer, companyId
        }

       const {data} = await axios.post(BASE_URL_CF + '/AIG_D2C_FEDERACION_GET_PLAN', body)
       
       return data
    } catch (error) {
        throw error
    }
}

export const getQuotePDF = async (quoteId,zipCode,catastrofico, companyId) => {
    try {
        console.log('URL',process.env.VUE_APP_BASE_URL_AUTOMARSH);
        return await axios.post(BASE_URL + '/_api/v1/quotes/downloadQuotePdf/',{quoteId,zipCode,catastrofico, companyId},{
        responseType: "blob",
      });
    } catch (error) {
        throw error
    }
}

export const convertToDollar = async () => {
    try {
        const response = await axios.get("https://openexchangerates.org/api/latest.json?app_id=a50fae975e3349f9bf6b1c480aeb5512");
        const values = response.data;
        const actualRateMXN = values.rates.MXN;
        return actualRateMXN;
    } catch (error) {
        throw error.message;
    }
}
